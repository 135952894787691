import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Expo | François Deguire' />
    <ProjectPage
      name='Expo'
      description={`
        The Expo is an exhibition of final projects bringing together graduates
        of the Université de Montréal. The furniture and lighting systems were
        redesigned, and a new identity was created in order to refresh the look
        of the show. A new wayfinding system of illuminated frames was devised to
        direct visitors and integrate perfectly with the space.
      `}
      meta={`
        For Université de Montréal<br>
        In 2017
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    expo1: file(relativePath: { eq: "expo/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    expo2: file(relativePath: { eq: "expo/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    expo3: file(relativePath: { eq: "expo/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    expo4: file(relativePath: { eq: "expo/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    expo5: file(relativePath: { eq: "expo/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    expo6: file(relativePath: { eq: "expo/6.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
